import TonWeb from './tonweb'

import { shortAddress } from '@/logic/functions'

const formatNanograms = TonWeb.utils.fromNano
const BN = TonWeb.utils.BN

export function setTransactions(txs) {
  $('#transactionsList').innerHTML = ''
  let date = ''

  toggle($('#walletCreated'), txs.length === 0)

  txs.forEach(tx => {
    tx.amount = new BN(tx.amount)
    tx.fee = new BN(tx.fee)
    tx.otherFee = new BN(tx.otherFee)
    tx.storageFee = new BN(tx.storageFee)
    tx.date = new Date(tx.date)

    const txDate = formatDate(tx.date)
    if (date !== txDate) {
        addDateSeparator(txDate)
        date = txDate
    }
    addTx(tx)
  })
}

function addDateSeparator(dateString) {
  $('#transactionsList').appendChild(createElement({tag: 'div', clazz: 'date-separator', text: dateString}))
}

function addTx(tx) {
  const isReceive = !tx.amount.isNeg()
  const amountFormatted = formatNanograms(tx.amount)
  const addr = isReceive ? tx.from_addr : tx.to_addr

  const item = createElement({
      tag: 'div',
      clazz: 'tx-item',
      child: [
          createElement({
              tag: 'div',
              child: isReceive ? [
                  createElement({
                      tag: 'span',
                      clazz: ['tx-amount', 'tx-amount-green'],
                      text: '+' + amountFormatted
                  }),
                  createElement({tag: 'div', clazz: ['btn-icon', 'bg-down-left', 'white']})
              ] : [
                  createElement({tag: 'span', clazz: 'tx-amount', text: amountFormatted}),
                  createElement({tag: 'div', clazz: ['btn-icon', 'bg-down-left', 'white', 'rotate-180']})
              ]
          }),
          setAddr(createElement({tag: 'div', address: addr, clazz: ['tx-addr', 'addr']}), shortAddress(addr, 10)),
          tx.comment ? createElement({tag: 'div', clazz: 'tx-comment', text: tx.comment}) : undefined,
          // createElement({tag: 'div', clazz: 'tx-fee', text: `Fees: ${formatNanograms(tx.fee)}`}),
          createElement({tag: 'div', clazz: 'tx-item-date', text: formatTime(tx.date)})
      ]
  })

  // item.addEventListener('click', () => onTransactionClick(tx))

  $('#transactionsList').appendChild(item)
}

function $(x) {
  return document.querySelector(x)
}

function toggle(div, visible) {
  let d = visible
  if (visible === true) d = 'block'
  if (visible === false) d = 'none'

  if (!div) return
  div.style.display = d
}

function createElement(params) {
  const item = document.createElement(params.tag)
  if (params.clazz) {
      if (Array.isArray(params.clazz)) {
          for (let c of params.clazz) {
              if (c) {
                  item.classList.add(c)
              }
          }
      } else {
          item.classList.add(params.clazz)
      }
  }
  if (params.address) item.setAttribute('address', params.address)
  if (params.text) item.innerText = params.text
  if (params.child) {
      for (let c of params.child) {
          if (c) {
              item.appendChild(c)
          }
      }
  }
  if (params.style) {
      for (let key in params.style) {
          item.style[key] = params.style[key]
      }
  }
  return item
}

function setAddr(el, s) {
  el.innerHTML = ''
  el.appendChild(document.createTextNode(s.substring(0, s.length / 2)))
  el.appendChild(document.createElement('wbr'))
  el.appendChild(document.createTextNode(s.substring(s.length / 2)))
  return el
}

function doubleZero(n) {
  if (n < 10) return '0' + n
  return n
}

function formatTime(date) {
  return doubleZero(date.getHours()) + ':' + doubleZero(date.getMinutes())
}

const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

function formatDate(date) {
  return MONTH_NAMES[date.getMonth()] + ' ' + date.getDate()
}