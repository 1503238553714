<template>
  <div id="main" class="screen visibility-hidden faded-show">
    <div class="head">
      <div class="head-row">
        <button @click="refresh" id="main_refreshBtn" class="btn-round bg-refresh"></button>
        <button @click="logout" id="main_settingsButton" class="btn-round bg-menu"></button>
      </div>
      <div class="balance-container">
        <div @click="openInNewTab(`https://tonscan.org/address/${address}`)" id="balance">
          <AnimatedNumber :value="balance" :decimals="6" />
        </div>
        <Lottie name="ton" class="balance-symbol" :style="{ marginTop: '-3px', width: '44px', height: '44px', position: 'relative' }" />
        <div class="balance-diamond-container" style="display: none;">
          <div id="diamond" style="display: none;"></div>
        </div>
      </div>

      <div v-tippy="{ text: 'Copy', placement: 'left' }" @click="copy(address)" class="address"><Typing speed="30" :text="shortAddress(address, 8)" /></div>

      <button @click="popup = 'send'" id="sendButton" class="btn-blue">
        <div class="btn-icon bg-down-left rotate-180"></div>
        Transfer
      </button>
    </div>

    <div id="transactionsContainer">
      <div v-show="lastTransactions.length > 0" id="transactionsList"></div>
      <div v-if="!lastTransactions.length" id="walletCreated">
        <div>Wallet Created</div>
      </div>
    </div>
  </div>

  <div v-show="popup" id="modal" class="visibility-hidden faded-show"></div>
  <div v-show="popup === 'send'" class="popup visibility-hidden faded-show">
    <div class="popup-title">Send TON</div>

    <div class="input-label">Recipient</div>
    <input id="toWalletInput" v-model="recipient" spellcheck="false" type="text" placeholder="Enter wallet address" />

    <div class="popup-grey-text">48-letter wallet address of the recipient</div>

    <div class="position-relative w-100">
      <div class="input-label">Amount</div>
      <div id="sendBalance">Balance: {{ balance }} 💎</div>
    </div>
    <input id="amountInput" @blur="onAmountBlur" v-model="amount" type="number" placeholder="0.0" />

    <div class="input-label mt-20">Comment</div>
    <input id="commentInput" v-model="comment" type="text" placeholder="" />

    <div class="input-label mt-20">Repeat</div>
    <input id="amountInput" @blur="onRepeatBlur" v-model="repeat" type="number" :max="MAX_TX" maxlength="3" placeholder="0" />

    <button @click="sendTransfers" id="send_btn" class="btn-blue mt-20">Send Transfers</button>

    <button @click="popup = ''" id="send_closeBtn" class="popup-close-btn black"></button>
  </div>
</template>

<script setup>
import { copy, onMounted, useRouter, ref, shortAddress, API, openInNewTab } from '@/logic/functions'
import { setTransactions } from '@/logic/setTX'
import { keyPairFromSeed } from 'ton-crypto'
import { Wallets } from 'ton3-contracts'
import Typing from '@/components/Common/Typing'
import AnimatedNumber from '@/components/Common/AnimatedNumber'

const router = useRouter()

const MAX_TX = 254

// function getBalanceText(type) {
//   if (type === 'int') {
//     return balance.value != 0 ? balance.value.toString().split('.')[0] : 0
//   }
//   if (type === 'decimal') {
//     return balance.value != 0 ? balance.value.toString().split('.')[1] : '00'
//   }
// }

const lastTransactions = ref([])
const popup = ref('')
const address = ref('')
const balance = ref(0)
const recipient = ref('')
const amount = ref(0)
const comment = ref('')
const repeat = ref(0)

function onRepeatBlur() {
  if (repeat.value > MAX_TX) {
    repeat.value = MAX_TX
  }
}

function onAmountBlur() {
  if (amount.value > balance.value) {
    amount.value = balance.value
  }
}

onMounted(async() => {
  const seed = localStorage.getItem('seed')
  if (!seed || seed?.length !== 64) {
    return router.push('/')
  }
  const wallet = await getWallet(seed)
  address.value = wallet.address
  await refresh()

  document.querySelectorAll('.tx-addr.addr').forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault()
      openInNewTab(`https://tonscan.org/address/${el.getAttribute('address')}`)
    })
  })
})

async function getWallet(seed) {
  const seedBuffer = Buffer.from(seed, 'hex')
  const { publicKey, secretKey } = keyPairFromSeed(seedBuffer)
  const wallet = new Wallets.ContractHighloadWalletV2(0, publicKey, 1)
  const address = wallet.address.toString('base64', { bounceable: true, urlSafe: true, workchain: 0 })
  return { address, publicKey, secretKey }
}

async function sendTransfers() {
  const seed = localStorage.getItem('seed')
  const transfers = {
    amount: amount.value,
    recipient: recipient.value,
    comment: comment.value,
    repeat: Math.min(MAX_TX, repeat.value),
    seed,
  }
  const result = await API('send_transfers', transfers)
  console.log(result)
  popup.value = ''
}

async function refresh() {
  await getBalance()
  await updateTransactions()
}

async function updateTransactions() {
  const tx = await API('get_transactions', { address: address.value })
  lastTransactions.value = tx
  setTransactions(tx)
}

async function getBalance() {
  const b = await API('get_balance', { address: address.value })
  balance.value = typeof b === 'object' ? 0 : b
}

function logout() {
  localStorage.removeItem('seed')
  router.push('/')
}
</script>

<style lang="sass" scoped>
@media (max-width: 768px)
  .head, .head-row
    width: 374px
    margin-bottom: 10px

.screen
  background-image: url(https://wallpaperaccess.com/full/5124737.jpg)
  background-size: cover

.address
  font-size: 14px
  margin-bottom: 20px
  color: #283f8a
  font-weight: 600
  background: #ffffff
  width: fit-content
  margin: auto
  border-radius: 4px
  padding: 4px 10px
  display: block
  cursor: pointer
  box-shadow: 0px 3px 8px 0 #0000000d

#send_btn
  color: white
  background-color: #5095e4
</style>